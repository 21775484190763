<template>
    <div id="bimil_Main">
        <div id="bimil_Header">
            <Header />
        </div>
        
        <div class="bimilDiv">
            <div class="bimil_Container">
                <section class="mainSection">
                    <div class="mainSection_Container">
                        <div class="txtContainer">
                            <div>
                                <img src="../../assets/images/icons/bimil.png" alt="">
                                <h2>BIMIL</h2>
                            </div>
                            <p>
                                BIMIL (BIM Integrated Lab) is a platform for Autodesk Revit® users,<br>
                                enabling them to select and download add-ins to enhance their workflows.<br><br>
        
                                It offers tools for better documentation and design, allowing architects,<br>
                                engineers, and designers to customize their Revit experience<br>
                                for improved efficiency and collaboration.
                            </p>

                            <a :href="fileUrl" download="BIMIL-1.0.6.2.exe" class="downLoad" @click="downloadFile">Download<br>
                                <span>(Version 1.0.6.2)</span>
                            </a>

                            <!-- <a href="https://bimpeers-my.sharepoint.com/personal/leesm_bimpeers_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fleesm%5Fbimpeers%5Fcom%2FDocuments%2FBIMIL%5FRelease%2FBIMIL%2D1%2E0%2E0%5FRelease%2Eexe&parent=%2Fpersonal%2Fleesm%5Fbimpeers%5Fcom%2FDocuments%2FBIMIL%5FRelease&ga=1" class="downLoad" target="_blank">Download<br>
                                <span>(Latest Version 1.0.0)</span>
                            </a> -->
                            <!-- ~ 5/15까진 링크로 연결 ~ ... 이휴 직접 설치로 연결 -->
                            <!-- <p class="ps">※ Until May 16th, installation can be accessed via an external link on the Autodesk website.
                            </p> -->
                            <!-- <p class="ps">※ Until May 16th, installation can be accessed via an external link on the Autodesk website.
                            </p> -->
                            <p class="essential">
                                ※ 일부 회사에서는 보안 프로그램으로 인해 서버 통신이 차단될 수 있습니다.<br>
                                &nbsp;&nbsp;&nbsp;이로 인해 인증 코드를 비롯한 실행에 문제가 발생할 수 있으니, 필요한 경우 관련 부서에 보안 해제를 요청하시기 바랍니다.
                            </p>
                        </div>
                        <img src="../../assets/images/bimil_edited.png" alt="" class="main">
                        
                    </div>
                </section>

                <section class="mediaSection">
                    <div>
                        <h2>Add-in Download Guide</h2>
                        <p>From BIMIL installation to add-in downloads, watch this video for a simple step-by-step guide.</p>
                    </div>
    
                    <div class="media">
                        <iframe width="1280" height="768" src="https://www.youtube.com/embed/2Zc_S-SOhcw?si=U3ltHBgw5icO0kmv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </section> 

                <section class="contentSection">
                    <div class="contentTit">
                        <h2>Enhance Your Revit Workflow with BIMIL Add-ins</h2>
                        <p>BIMIL offers a variety of add-ins to improve workflows and increase efficiency for Revit users.<br>
                            Discover add-ins that can be used in various areas, including documentation, design, and collaboration.</p>
                    </div>
    
                    <div class="content_box">
                        <div>
                            <div class="img_Wrapper">
                                <img src="../../assets/images/icons/GridtoCrop_icon64.png" alt="">
                            </div>
                            
                            <h4>Grid to Crop</h4>
                            <p>Align Grids and Levels with Crop Region boundaries</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../../assets/images/icons/AutoDimension_icon64.png" alt="">
                            </div>
                            <h4>Auto Dimension</h4>
                            <p>Automatically generate dimension lines for Grids and Levels</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../../assets/images/icons/ExcelLink_icon64.png" alt="">
                            </div>
                            <h4>Excel Link</h4>
                            <p>Upload and insert Excel data directly into Revit views</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../../assets/images/icons/AutoJoin_icon64.png" alt="">
                            </div>
                            <h4>Auto Join</h4>
                            <p>Customizable joining of geometries by category preferences</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../../assets/images/icons/AutoMaterialTag_icon64.png" alt="">
                            </div>
                            <h4>Auto Material Tag</h4>
                            <p>Two-click material tag creation for layered objects</p>
                        </div>
                        <div>
                            <div class="cmDate">
                                <span class="cmDate">May 2024</span>
                            </div>
                            <div class="product_ContainerBox">
                                <div class="img_Wrapper">
                                    <img src="../../assets/images/icons/comingSoon.png" alt="">
                                </div>
                                <h4>Coming Soon</h4>
                                <p>New tools added monthly<br>to boost your Revit workflow</p>
                            </div>
                        </div>
                    </div>
                </section> 

                <section class="itemSection">
                    <div class="itemContainer">
                        <div class="mediaItem">
                            <iframe width="670" height="450" src="https://www.youtube.com/embed/lIYa6BWZmAQ?si=5kVVqbJXMr7_fxbf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../../assets/images/icons/GridtoCrop_icon64.png" alt="">
                                </div>
                                <h4>Grid to Crop</h4>
                            </div>
                            <p>Align Grids and Levels with Crop Region boundaries</p>
    
                            <ul>
                                <li>One-click adjustment of spacing and positioning</li>
                                <li>Customizable spacing between Grids/Levels and Crop Regions</li>
                                <li>Operates only within the current view, without affecting others</li>
                            </ul>
                        </div>
                    </div>

                    <div class="itemContainer">
                        <div class="item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../../assets/images/icons/AutoDimension_icon64.png" alt="">
                                </div>
                                <h4>Auto Dimension</h4>
                            </div>
                            <p>Automatically generate dimension lines for Grids and Levels</p>
    
                            <ul>
                                <li>One-click creation of dimensions for visible Grids and Levels</li>
                                <li>Adjustable dimension line spacing for tailored documentation</li>
                                <li>Selection of dimension line types to fit project specifications</li>
                            </ul>
                        </div>
                        <div class="mediaItem">
                            <iframe width="670" height="450" src="https://www.youtube.com/embed/JhtXOX2orlA?si=KWT27CNoy3HKOaqW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div class="itemContainer">
                        <div class="mediaItem">
                            <iframe width="670" height="450" src="https://www.youtube.com/embed/Jp7pwaUHRcQ?si=s_W06Bk1YIxCWFo8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../../assets/images/icons/ExcelLink_icon64.png" alt="">
                                </div>
                                <h4>Excel Link</h4>
                            </div>
                            <p>Upload and insert Excel data directly into Revit views</p>
    
                            <ul>
                                <li>Seamless updates to Revit from modified Excel files</li>
                                <li>Clipboard content conversion into Revit tables
                                </li>
                                <li>Flexible formatting for Legends, Drafting Views, or Schedules
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="itemContainer">
                        <div class="item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../../assets/images/icons/AutoJoin_icon64.png" alt="">
                                </div>
                                <h4>Auto Join</h4>
                            </div>
                            <p>Customizable joining of geometries by category preferences
                            </p>
    
                            <ul>
                                <li>Dynamic priority setting for flexible modeling control</li>
                                <li>Wide application scope: all objects, visible objects, or selected objects
                                </li>
                                <li>Efficient management of join and unjoin operations</li>
                            </ul>
                        </div>
                        <div class="mediaItem">
                            <iframe width="670" height="450" src="https://www.youtube.com/embed/AcSQ5MnXtFg?si=THdSrXwh0Q_dLGkC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div class="itemContainer">
                        <div class="mediaItem">
                            <iframe width="670" height="450" src="https://www.youtube.com/embed/-O9Jy083W2k?si=JerPEPP9rWz9STgp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../../assets/images/icons/AutoMaterialTag_icon64.png" alt="">
                                </div>
                                <h4>Auto Material Tag</h4>
                            </div>
                            <p>Two-click material tag creation for layered objects
                            </p>
    
                            <ul>
                                <li>Customizable display options for material type and thickness
                                </li>
                                <li>Dynamic tag updating to reflect changes in material layers
                                </li>
                                <li>Manual adjustment of tag visibility and orientation
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div id="bimil_Footer">
            <Footer />
        </div>
    </div>
</template>

<style scoped>
@import url('../../assets/css/bimil.css');
Footer_Wrapper {
    grid-row: 3;
}
</style>

<script>
import Header from "../../assets/components/PageHeader.vue";
import Footer from "../../assets/components/PageFooter.vue";
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            fileUrl: 'https://www.bimpeers.com/release/BIMIL-1.0.6.2_Release.exe'
        }
    },
    methods: {
        downloadFile(event) {
            event.stopPropagation();
            // const fileUrl = 'http://localhost:8082/files/BIMIL-1.0.0_Release.exe';
            // window.open(fileUrl);
            // this.$router.push('/bimil/release');
        }
    }
}
</script>