<template>
    <div id="Header_Root">
        <div id="Header_Contents">
            <router-link to="/" id="logo">
                <img src="../images/logo/logo-w.png">
            </router-link>
            <div id="Menu_Container">
                <li></li>
                <div class="Menu_Item">
                    <span>About</span>
                    <div class="Menu_Bar"></div>
                </div>
                <div class="Menu_Item">
                    <span>Product</span>
                    <div class="Menu_Bar"></div>
                </div>
                <div class="Menu_Item">
                    <span>Project</span>
                    <div class="Menu_Bar"></div>
                </div>
                <div class="Menu_Item">
                    <span>People</span>
                    <div class="Menu_Bar"></div>
                </div>
                <div class="Menu_Item">
                    <span>Recruit</span>
                    <div class="Menu_Bar"></div>
                </div>
                <div id="Menu_Contents">
                    <li></li>
                    <ul class="SideItem_Wapper">
                        <router-link to="/about/history">회사연혁</router-link>
                        <router-link to="/about/vision">Vision</router-link>
                        <router-link to="/about/ci">CI소개</router-link>
                        <router-link to="/about/contact">오시는 길</router-link>
                        <li></li>
                        <div class="SideItem_Bar"></div>
                    </ul>
                    <ul class="SideItem_Wapper">
                        <router-link to="/bimil">BIMIL</router-link>
                        <router-link to="/product">제품 소개</router-link>
                        <li></li>
                        <li></li>
                        <li></li>
                        <div class="SideItem_Bar"></div>
                    </ul>
                    <ul class="SideItem_Wapper">
                        <router-link to="/project" id="proLink">프로젝트 소개</router-link>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <div class="SideItem_Bar"></div>
                    </ul>
                    <ul class="SideItem_Wapper">
                        <router-link to="/people/talent">인재상</router-link>
                        <router-link to="/people/department">인사제도</router-link>
                        <router-link to="/people/members">Peers</router-link>
                        <li></li>
                        <li></li>
                        <div class="SideItem_Bar"></div>
                    </ul>
                    <ul class="SideItem_Wapper">
                        <router-link to="/recruit/process">채용프로세스</router-link>
                        <router-link to="/recruit/list">채용정보</router-link>
                        <li></li>
                        <li></li>
                        <li></li>
                        <div class="SideItem_Bar"></div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped> 
@charset "UTF-8";

#Header_Root{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1610px 1fr;
    width: 100%;
    height: 80px;
    background: #042a44;
}

#Header_Contents{
    grid-column: 2;
    display: grid;
    width: 100%;
    grid-template-columns : 55px 1fr 55px;
    justify-content: space-evenly;
    align-items: center;
}

#logo img{
    width: 55px;
}

#Menu_Container{
    display: grid;
    grid-template-columns:200px repeat(5,1fr) 200px;
    grid-column: 2;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
    z-index: 500;
}

#Menu_Container .Menu_Item{
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: "Pretendard, sans-serif";
    text-align: center;
    color: rgb(120, 120, 120);
}

#Menu_Container .Menu_Item:hover{
    color: #4d8fcc;
}

#Menu_Container .Menu_Item:hover>.Menu_Bar{
    background: #4d8fcc;
}

#Menu_Container .Menu_Item:hover~#Menu_Contents{
    display: grid;
}

#Menu_Contents{
    display: none;
    grid-template-columns:255px repeat(5,1fr) 255px;
    position: absolute;
    bottom: -180px;
    width: 1610px;
    height: 180px;
    background: #f3f3f3;
    border-radius: 4px;
    align-items: start;
    justify-items: center;
}

#Menu_Contents:hover{
    display: grid;
}


.Menu_Item{
    height: 80px;
    line-height: 80px;
}

.Menu_Item span{
    color: #fff;
}

.SideItem_Wapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #787878;
    width: 100%;
    height: 180px;
    border-right: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
}

.SideItem_Wapper a{
    padding: 1rem 0px;
    height: 2rem;
    color: #787878;
}
.SideItem_Wapper li{
    padding: 1rem 0px;
    height: 2rem;
}

.SideItem_Wapper a:visited{
    color: #787878;
}

.SideItem_Wapper a:hover{
    color: #444444;
}

.SideItem_Wapper:hover .SideItem_Bar{
    background: #4d8fcc;
}

.Menu_Bar{
    display: block;
    position: relative;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 5px;
    z-index: 101;
    background: transparent;
}
.SideItem_Bar{
    display: block;
    position: relative;
    left: 0%;
    bottom: calc(100% - 20px);
    width: 100%;
    height: 5px;
    z-index: 101;
    background: transparent;
    box-sizing: border-box;
}

</style>


<script>
import router from '@/router';

export default {
    data() {
        return {
            headerBackColor : '#021623',
            headerFontColor : '#fff',
        };
    },
    mounted()
    {
        const curRouteName = router.currentRoute.value.name;
        if(curRouteName != 'Main')
        {
            this.headerBackColor = '#fff';
            this.headerFontColor = '#787878';
        }
    },
    methods: {
    },
};
</script>