<template>
    <div id="MainPage_Wrapper" v-show="isInit">
        <div id="Main_Header">
            <Header />
        </div>
        
        <div id="MainPage_Body">
            <div class="Section1_Wrapper">
                
                <div class="Section1_Container">
                    <div class="Section1_Tit">
                        <h2>
                            <strong>빔피어스(BIMPeers)</strong>는<br>
                            건축/건설분야에 소프트웨어를<br>
                            개발하고 보급하는 회사입니다
                        </h2>
                    </div>

                    <div class="bg_Container">
                        <img src="../assets/images/bb.png" alt="">
                    </div>
                </div>

                
            </div>
        
            <div class="Section2_Wrapper" ref="section2">

                <div class="Section2_Container">
                    
                    <div class="Section2_Content">
                        <div class="Section2_Txt">
                            <h2>  <strong>기술선도와<br>품질경영</strong>으로<br>16년동안 고객사와 함께 성장해 왔습니다.</h2>
                            <!-- <p>기술선도와 품질경영으로 15년동안 고객사와 함께 성장해 왔습니다</p> -->
                        </div>

                        <div class="Section2_Graph">
                            <!-- <div class="Graph_Year">
                                <p>BIMPeers가 걸어온 시간</p>
                                <h1>
                                    <strong ref="yearRef">0</strong>년
                                </h1>
                            </div> -->

                            <p class="ColumnBar"></p>
                            
                            <div class="Graph_Project">
                                <div class="Graph_Project_Tit">
                                    <p>수행한 프로젝트</p>
                                    <router-link to="/project" style="z-index: 99999;"> 
                                        
                                    </router-link>
                                </div>

                                <h1>
                                    <strong ref="projectRef">0</strong>+
                                </h1>

                                <!-- <router-link to="/"> 
                                    <div class="Graph_ProjectOverlay">
                                        프로젝트 바로가기
                                    </div>
                                </router-link> -->
                            </div>

                            <!-- <p class="RowBar"></p> -->

                            <div class="Graph_Client">
                                <div class="Graph_Client_Tit">
                                    <p>함께한 고객사</p>
                                    <router-link to="/"> 
                                        <img src="../../public/img//icons/router.png" alt="">
                                    </router-link>
                                </div>
                                
                                <h1>
                                    <strong ref="clientRef">0</strong>+
                                </h1>

                            </div>
                        </div>

                        
                        <div class="Client_List" ref="section32">
                            <img src="../assets/images/LogoW.png" alt="">
                            <img src="../assets/images/customers/gansam.gif" alt="">
                            <img src="../assets/images/customers/samsungEngi.gif" alt="">
                            <img src="../assets/images/customers/customer_29.gif" alt="">
                            <img src="../assets/images/customers/customer_28.gif" alt="">
                            <img src="../assets/images/customers/customer_27.gif" alt="">
                            <img src="../assets/images/customers/customer_26.gif" alt="">
                            <img src="../assets/images/customers/customer_25.gif" alt="">
                            <img src="../assets/images/customers/customer_24.gif" alt="">
                            <img src="../assets/images/customers/customer_23.gif" alt="">
                            <img src="../assets/images/customers/customer_22.gif" alt="">
                            <img src="../assets/images/customers/customer_21.gif" alt="">
                            <img src="../assets/images/customers/customer_20.gif" alt="">
                            <img src="../assets/images/customers/customer_19.gif" alt="">
                            <img src="../assets/images/customers/customer_18.gif" alt="">
                            <img src="../assets/images/customers/customer_17.gif" alt="">
                            <img src="../assets/images/customers/customer_16.gif" alt="">
                            <img src="../assets/images/customers/customer_15.gif" alt="">
                            <img src="../assets/images/customers/customer_14.gif" alt="">
                            <img src="../assets/images/customers/customer_13.gif" alt="">
                            <img src="../assets/images/customers/customer_12.gif" alt="">
                            <img src="../assets/images/customers/customer_11.gif" alt="">
                            <img src="../assets/images/customers/customer_10.gif" alt="">
                            <img src="../assets/images/customers/customer_9.gif" alt="">
                            <img src="../assets/images/customers/customer_8.gif" alt="">
                            <img src="../assets/images/customers/customer_7.gif" alt="">
                            <img src="../assets/images/customers/customer_6.gif" alt="">
                            <img src="../assets/images/customers/customer_5.gif" alt="">
                            <img src="../assets/images/customers/customer_4.gif" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="Section3_Wrapper">
                <div class="Section3_Container">
                    
                    
                    <div class="Section3_Tit">
                        <h2>
                            <strong>Our Product</strong>
                        </h2>
                        <p>
                            BIMPeers는 프로젝트를 효율적으로 통합관리 할 수 있는 솔루션을 제공합니다.
                        </p>
                        <a class="GoProduct" href="/product">View More</a>
                    </div>
                    

                    <div class="Section3_Content">
                        <div class="Product_List_Left">
                            <div class="item">
                                <div class="Slider_Containers">
                  
                                    <div class="Slider" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
                                      <!-- <img src="../../assets//images//icons/router2.png" class="prev" @click="prevSlide"> -->
                                      <div class="Slider_Item" v-for="(image, index) in images" :key="index">
                                        <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlide"> -->
                                        <img :src="image" alt="Image {{index + 1}}">
                                        <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlide"> -->
                                      </div>
                                      <!-- <img src="../../assets//images//icons/router2.png" class="next" @click="nextSlide"> -->
                    
                                    </div>
                                    
                                    <div class="Slider_Buttons">
                                      <button v-for="(image, index) in images" :key="index" @click="goToSlide(index)" :class="{ active: index === currentSlide}">
                                        {{index + 1}}
                                      </button>
                                    </div>
                                    
                                </div>
                            </div>
                            <ul>
                                <li class="bar"></li>
                                <h4>BIM 모델링 자동화 프로그램</h4>
                                <li>- Revit 기반의 모델링 자동화 Add-in 소프트웨어 개발</li>
                                <li>- 업무프로세스 분석 및 업무 자동화 컨설팅</li>
                            </ul>
                        </div>
                        
                        <div class="Product_List_Right">
                            <ul>
                                <li class="bar"></li>
                                <h4>협업지원시스템</h4>
                                <li>- 건축, 건설, 엔지니어링 업체들의 사내 운영 시스템 개발</li>
                                <li>- 게시판, 일정관리, 연락처 관리 등 맞춤형 개발</li>
                            </ul>
    
                            <div class="item">
                                <div class="Slider_Containers">
                                    <div class="Slider" :style="{ transform: `translateX(-${currentWv * 100}%)` }">
                                      <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                                      <div class="Slider_Item" v-for="(image, index) in Wvimages" :key="index">
                                        <img :src="image" alt="Image {{index + 1}}">
                                      </div>
                                      <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                                    </div>
                              
                                    <div class="Slider_Buttons">
                                      <button v-for="(image, index) in Wvimages" :key="index" @click="goToSlideWv(index)" :class="{ active: index === currentWv}">
                                        {{index + 1}}
                                      </button>
                                    </div>
                                  </div>
                            </div>
                        </div>
    
                        <div class="Product_List_Left">
                            <div class="Slider_Containers">
                                <div class="Slider" :style="{ transform: `translateX(-${currentPr * 100}%)` }">
                                  <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                                  <div class="Slider_Item" v-for="(image, index) in Primages" :key="index">
                                    <img :src="image" alt="Image {{index + 1}}">
                                  </div>
                                  <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                                </div>
                          
                                <div class="Slider_Buttons">
                                  <button v-for="(image, index) in Primages" :key="index" @click="goToSlidePr(index)" :class="{ active: index === currentPr}">
                                    {{index + 1}}
                                  </button>
                                </div>
                              </div>
                            <ul>
                                <li class="bar"></li>
                                <h4>프로젝트 관리 시스템</h4>
                                <li>- 용역 수행을 하는 회사들의 프로젝트 관리를 도와주는 시스템 개발</li>
                                <li>- 전자 결재, 프로젝트 투입 자원 분석, 참여인원들 간의 의사소통 지원</li>
                            </ul>
                        </div>
    
                        <div class="Product_List_Right">
                            <ul>
                                <li class="bar"></li>
                                <h4>BIM 라이브러리관리 시스템</h4>
                                <li>- 라이브러리 관리방안 컨설팅</li>
                                <li>- BIM 기반의 설계프로젝트 진행에 사용되는 라이브러리 통합 관리 시스템</li>
                            </ul>
    
                            <div class="item">
                                <div class="Slider_Containers">
                                  <div class="Slider" :style="{ transform: `translateX(-${currentPs * 100}%)` }">
                                    <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                                    <div class="Slider_Item" v-for="(image, index) in Psimages" :key="index">
                                      <img :src="image" alt="Image {{index + 1}}">
                                    </div>
                                    <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                                  </div>
                            
                                  <div class="Slider_Buttons wide">
                                    <button v-for="(image, index) in Psimages" :key="index" @click="goToSlidePs(index)" :class="{ active: index === currentPs}">
                                      {{index + 1}}
                                    </button>
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="Product_List_Right">
                           
    
                            <div>
                                <!-- <img src="../assets/images/product/" alt=""> -->
                            </div>

                            <ul>
                                <li class="bar"></li>
                                <h4>기타 응용프로그램 개발</h4>
                                <li>- 웹, 데스크탑, 모바일 기반의 솔루션 개발</li>
                                <li>- 최신의 기술 (Ajax, Rss, RIA 등)을 활용한 솔루션 구축 가능</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div id="Main_Footer">
            <Footer />
        </div>
    </div>
</template>

<style scoped>
@import '../assets/css/MainPage.css';
#app {
}
.slider {
    transition: transform 1s ease;
  }
  .active {
    background-color: #4d8fcc; 
    color: #4d8fcc; 
  }
  #FooterWrapper {
    position: relative;
  }
</style>


<script>
import Header from "../assets/components/PageHeader.vue";
import Footer from "../assets/components/PageFooter.vue"

export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            section2Observer:null,
            isAnimationStarted:false,
            isSideMenuVisible:false,
            isHeaderVisible:true,
            isHoverVisible:false,
            hoverBoxStyle: {
                top: '0px',
                left: '0px',
                display: 'none',
            },
            currentIndex:0,
            currentSlide:0,
            currentWv:0,
            currentPr:0,
            currentPs:0,
            images: [
                require('@/assets/images/product/Modeling/modeling_01.jpg'),
                require('@/assets/images/product/Modeling/modeling_02.jpg'),
                require('@/assets/images/product/Modeling/modeling_03.jpg'),
            ],
            Wvimages: [
                require('@/assets/images/product/Collabo/collabo_01.png'),
                require('@/assets/images/product/Collabo/collabo_02.png'),
                require('@/assets/images/product/Collabo/collabo_03.png'),
            ],
            Primages: [
                require('@/assets/images/product/Management/xeno.png'),
                require('@/assets/images/product/Management/pr_01.png'),
                require('@/assets/images/product/Management/pr_02.png'),
                require('@/assets/images/product/Management/pr_03.png'),
                require('@/assets/images/product/Management/pr_04.png'),
                require('@/assets/images/product/Management/pr_05.png'),
            ],
            Psimages: [
                require('@/assets/images/product/Library/library_01.png'),
                require('@/assets/images/product/Library/library_02.png'),
                require('@/assets/images/product/Library/library_03.png'),
                require('@/assets/images/product/Library/library_04.png'),
                require('@/assets/images/product/Library/library_05.png'),
                require('@/assets/images/product/Library/library_06.png'),
                require('@/assets/images/product/Library/image5.png'),
                require('@/assets/images/product/Library/image7.png'),
                require('@/assets/images/product/Library/image10.png'),
                require('@/assets/images/product/Library/image11.png'),
            ],
            isInit : false,
        }
    },

    computed: {
        // currentImage() {
        //     return this.images[this.currentIndex];
        // },
        imagesCount() {
            return this.images.length;
        },
    },
    beforeCreate(){
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if(isMobile)
        {
            window.location.assign("https://m.bimpeers.com/")
        }
    },
    mounted(){
        this.section2Observer = new IntersectionObserver(this.handleIntersection, {
            root: null, // viewport를 루트로 사용
            rootMargin: '0px', // 루트와의 마진
            threshold: 0.1, // 요소가 화면에 10% 이상 나타날 때 콜백 실행
        });
        this.section2Observer.observe(this.$refs.section2);

        this.animationNumber(150, this.$refs.projectRef);
        this.animationNumber(60, this.$refs.clientRef);

        setInterval(this.nextImage, 7000); // 7초마다 이미지 전환

        this.$nextTick(()=>{
            this.isInit = true;
            const head = document.getElementsByTagName('head')[0];
            const gMapScript = document.createElement('script');
            gMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCDb2OD7EQU28pOLenddpql_fXmsojQpFk&callback=initMap';
            gMapScript.async = true;
            gMapScript.type = 'text/javascript';
            gMapScript.defer = true;
            head.append(gMapScript);
        });
        this.deleteCookie("currentPage");
        this.deleteCookie("selectedOption");
        this.deleteCookie("selectedFilter");
    },

    methods: {
        deleteCookie(name) {
	        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        goToSlide(index) {
        this.currentSlide = index;
        },

        goToSlideWv(index) {
            this.currentWv = index;
        },

        goToSlidePr(index) {
            this.currentPr = index;
        },

        goToSlidePs(index) {
            this.currentPs = index;
        },

        prevSlide() {
        this.currentSlide = (this.currentSlide - 1 + this.imagesCount) % this.imagesCount;
        },
        nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.imagesCount;
        },
        prevSlideWv() {
        this.currentWv = (this.currentWv - 1 + this.imagesCount) % this.imagesCount;
        },
        nextSlideWv() {
        this.currentWv = (this.currentWv + 1) % this.imagesCount;
        },
        prevSlidePr() {
        this.currentPr = (this.currentPr - 1 + this.imagesCount) % this.imagesCount;
        },
        nextSlidePr() {
        this.currentPr = (this.currentPr + 1) % this.imagesCount;
        },
        prevSlidePs() {
        this.currentPs = (this.currentPs - 1 + this.imagesCount) % this.imagesCount;
        },
        nextSlidePs() {
        this.currentPs = (this.currentPs + 1) % this.imagesCount;
        },



        handleIntersection(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) 
                {
                    this.isAnimationStarted = true;
                    // 요소가 화면에 나타났을 때 숫자 카운트 애니메이션 실행
                    this.animationNumber(150, this.$refs.projectRef);
                    this.animationNumber(60, this.$refs.clientRef, 1.2);
                    this.isAnimationStarted = true;

                    this.section2Observer.unobserve(this.$refs.section2);
                }
            });
        },


        animationNumber(target, ref, animationStep = 1) {
            let current = 0;
            const step = (target / 50) * animationStep;

            const animation = () => {
                if (current < target) {
                    current += step;
                    ref.innerHTML = Math.round(current);
                    requestAnimationFrame(animation);
                } else {
                    ref.innerHTML = target;
                }
            };
            requestAnimationFrame(animation);
        },

        showSideMenu(menu) {
            this.isSideMenuVisible = true;

            if (menu === 'about') {
                // About 클래스에 border 스타일 추가
                this.showHoverBox(78, 420);
            }
            else if (menu === 'people') {
                this.showHoverBox(78, 1050);
            } else if (menu === 'recruit') {
                this.showHoverBox(78, 1265); 
            }
        }, 

        hideSideMenu() {
            this.isSideMenuVisible = false;
        },

        showHoverBox(top, left) {
            this.hoverBoxStyle.top = top + 'px';
            this.hoverBoxStyle.left = left + 'px';
            this.hoverBoxStyle.display = 'block';
        },
        hideHoverBox() {
            this.hoverBoxStyle.display = 'none';
        },

        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        },


    
    },

    created() {
    const slideInterval = 3500; // 2.5초마다 슬라이드 변경


    // 슬라이드 섹션별로 setInterval 설정
    setInterval(() => {
      this.nextSlide();
    }, slideInterval);

    setInterval(() => {
      this.nextSlideWv();
    }, slideInterval);

    setInterval(() => {
      this.nextSlidePr();
    }, slideInterval);

    setInterval(() => {
      this.nextSlidePs();
    }, slideInterval);
  }
};
</script>